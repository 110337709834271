window.addEventListener("scroll", colorMenu);
window.addEventListener("scroll", hideMenu)
let isChanging = false;
var lastScrollTop = 0;


function hideMenu() {
    var openMenu = document.getElementsByClassName('nav-mobile-show')
    var menuActive = document.getElementsByClassName('show-nav');

    if(!openMenu.length && !menuActive.length) {
        var header = document.getElementById('header-container');
        var st = window.pageYOffset || document.documentElement.scrollTop;
        if (st > lastScrollTop) {
            if (window.pageYOffset >= 1) {
                header.style.opacity = '0';
                header.style.pointerEvents = 'none'


            }
        } else {
            header.style.opacity = '1';
            header.style.pointerEvents = 'auto'



        }
        lastScrollTop = st <= 0 ? 0 : st;
    }
}


function colorMenu (){
    let menu = document.getElementById('site-navigation')
    let wrapper = document.querySelector('.menu-wrapper')
    let menuTop = document.getElementById('header-top-bar')
    var openMenu = document.getElementsByClassName('nav-mobile-show')
    let header = document.getElementById('header-container')


    if(!openMenu.length) {

        if (isChanging) {
            return
        }

        if (window.pageYOffset >= 1 && !wrapper.classList.contains('white-nav')) {
            wrapper.classList.add('white-nav')
            header.classList.add('full-color')

            isChanging = true;
            setTimeout(function () {
                isChanging = false;
            }, 300)
        } else if (window.pageYOffset < 1 && wrapper.classList.contains('white-nav')) {
            wrapper.classList.remove('white-nav')
            header.classList.remove('full-color')

            isChanging = true;
            setTimeout(function () {
                isChanging = false;
            }, 300)
        }

    }
}