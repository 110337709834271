const $pageHero = $('.page-hero');
if($pageHero){
    $(document).ready(function () {
        const $scrollWrapper = $pageHero.find('.scroll-wrapper');
        $(window).scroll(function (e) {
            const $this = $(this);
            const offsetTop = $this.scrollTop();
            const scrollWrapperPosition = offsetTop >= 1 ? 'absolute' : 'fixed';
            $scrollWrapper.css('position', scrollWrapperPosition);
        })
    });
}
