import { intersectionObserve } from "./_intersection-observer";

const observedElements = document.querySelectorAll('.observed');

intersectionObserve(observedElements, 0, (element, intersectionRatio) => {
    (function(el) {


        if (intersectionRatio > 0 ) {

            element.classList.add('animateIn');
        } else if (intersectionRatio <= 0) {


        }








    })(element);

});