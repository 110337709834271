const $frontpageHero = $('.frontpage-hero');
if ($frontpageHero) {
    $(document).ready(function () {
        setTimeout(function() {
            $frontpageHero.addClass('fade-in');

            setTimeout(function() {
                $frontpageHero.addClass('show-1')
            }, 1000);
        }, 100);

        const $overlay1 = $frontpageHero.find('.overlay-1');
        const $overlay2 = $frontpageHero.find('.overlay-2');
        const overlay1StartX = 65; // in percent
        const overlay2StartX = 85; // in percent
        const $scrollWrapper = $frontpageHero.find('.scroll-wrapper');

        $(window).scroll(function (e) {
            const $this = $(this);
            const offsetTop = $this.scrollTop();

            if (offsetTop > 20 && offsetTop < innerHeight) {
                if ($frontpageHero.hasClass('show-1')) {
                    $frontpageHero.addClass('hide-1');
                    $frontpageHero.removeClass('show-1');
                }

                if (!$frontpageHero.hasClass('show-2')) {
                    $frontpageHero.removeClass('hide-2');
                    $frontpageHero.addClass('show-2');
                }
            } else if (offsetTop >= innerHeight) {
                if (!$frontpageHero.hasClass('hide-2')) {
                    $frontpageHero.addClass('hide-2');
                    $frontpageHero.removeClass('show-2');
                }
                if ($frontpageHero.hasClass('show-1')) {
                    $frontpageHero.addClass('hide-1');
                    $frontpageHero.removeClass('show-1');
                }
            } else {
                $frontpageHero.addClass('show-1');
                $frontpageHero.removeClass('hide-1');
                $frontpageHero.removeClass('show-2');
            }

            const scrollWrapperPosition = offsetTop >= innerHeight ? 'absolute' : 'fixed';
            $scrollWrapper.css('position', scrollWrapperPosition);
        });

        $overlay1.addClass('move');
        setInterval(function() {
            $overlay1.toggleClass('move');
        }, 3000);

        $overlay2.addClass('move');
        setInterval(function() {
            $overlay2.toggleClass('move');
        }, 2500);
    });
}
