var menuButton = document.querySelector('.menu-button');
var header = document.getElementsByTagName('header')[0];
var body = document.getElementsByTagName('body')[0];
var navMenu = document.querySelector('.nav-menu');
if (window.screen.width < 1440) {
    navMenu.classList.add('not-display');
}

function hideMenu() {
    menuButton.classList.remove('open');
    header.classList.remove('show-nav');
    setTimeout(function () {
        header.classList.remove('set-height');
    }, 500);
    body.classList.remove('no-scroll-y');
    setTimeout(function () {
        navMenu.classList.add('not-display');
    }, 500);
}

if (menuButton) {
    menuButton.addEventListener('click', function () {
        if (menuButton.classList.contains('open')) {
            hideMenu();
        } else {
            menuButton.classList.add('open');
            header.classList.add('set-height');
            setTimeout(function () {
                header.classList.add('show-nav');
            }, 100);
            body.classList.add('no-scroll-y');
            navMenu.classList.remove('not-display');
        }
    });
}