$newsblock = $('.news');

if ($newsblock.length > 0) {
    $columnWrappers = $newsblock.find('.column-wrapper');
    $newsButtonWrapper = $('.button-wrapper');
    $newsButton = $newsButtonWrapper.find('button');
    let buttonLoop = 0;

    if ($columnWrappers.length > 1) {
        $newsButton.click(function () {
            // Have we reached the end of the posts? Go to the beginning
            if (buttonLoop >= $columnWrappers.length - 1) {
                $columnWrappers.addClass('hide');
                $($columnWrappers[0]).removeClass('hide');
                buttonLoop = 0;
            } else {
                $($columnWrappers[buttonLoop + 1]).removeClass('hide');
                buttonLoop++;
            }

            // Update "More news" button text
            $buttonText = $newsButton.find('span');
            $buttonSvg = $newsButton.find('svg');
            const language = $buttonText.data('lang');
            if (buttonLoop >= $columnWrappers.length - 1) {
                $buttonText.text(language === 'en' ? 'Fewer news articles' : 'Færre nyheter');
                $buttonSvg.css({'transform': 'rotate(180deg)'})
            } else {
                $buttonText.text(language === 'en' ? 'More news articles' : 'Flere nyheter');
                $buttonSvg.css({'transform': 'rotate(0)'})
            }
        });
    }
}
